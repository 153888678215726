
#LoginButt {
  float: right;
}

.page-header {
  flex: 0 0 20%; /* 20% of height */
  background-color: #f8f9fa; /* Light background */
  display: flex;
  justify-content: space-between; /* Space between the header text and button */
  align-items: center; /* Vertically center the content */
  padding: 0 20px; /* Add some padding */
}

/* Style the login button */
.login-button {
  background-color: #007bff; /* Bootstrap primary color */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}
html, body {
  height: 100%; /* Ensures the page takes full height */
  margin: 0;
}

.carousel, .carousel-inner, .carousel-item {
  height: 80vh; /* Full viewport height */
}

.carousel-inner {
  position: fixed;
  bottom: 0;
}

.carousel-item img {
  object-fit: cover; /* Ensures the image covers the entire area */
  height: 100%;
  width: 100%;
}

/* Hide the carousel indicators (dots) */
.carousel-indicators {
  display: none;
}


.App {
  text-align: center;
}


main {
  padding: 20px;
}

.carousel-item{
  min-height: 100%;
  background-color: black;
}

#tree {
  align-items: center;
  text-align: center;
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 100%;
}

#str1 {
  height: 80%;
  align-content: center;
}
#str2 {
  height: 100%;
  align-content: center;
}
#str3 {
  height: 100%;
  align-content: center;
}

#int1 {
  height: 100%;
  align-content: center;
}
#int2 {
  height: 100%;
  align-content: center;
}
#int3 {
  height: 100%;
  align-content: center;
}

#soc1 {
  height: 100%;
  align-content: center;
}
#soc2 {
  height: 100%;
  align-content: center;
}
#soc3 {
  height: 100%;
  align-content: center;
}