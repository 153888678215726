

body {
    
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
  }

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  form {
    background-color: #f2f2f2;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  form h2 {
    margin-bottom: 20px;
  }
  
  form div {
    margin-bottom: 10px;
  }
  
  form label {
    margin-right: 10px;
  }
  
  form input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  form button {
    padding: 10px 20px;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }